import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 480,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
  listStyleType: "none",
}
const listItemStyles = {
  marginBottom: 12,
  fontWeight: "300",
  letterSpacing: 1,
}
const linkStyles = {
  color: "#8954A8",
}

// data
const links = [
  {
    text: "Home",
    url: "https://gaugebuilt.com/",
  },
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Gauge Construction Management & Consulting</title>
      <h1 style={headingStyles}>
        Gauge Construction Management & Consulting
        <br />
        <br />
        <span style={headingAccentStyles}>Contact drew@gaugebuilt.com / +1-512-968-2432</span>
      </h1>
    </main>
  )
}

export default IndexPage
